/* eslint-disable jsx-a11y/media-has-caption */

import { useEffect, useRef, useState } from 'react';

import { Box, Chip, SxProps } from '@mui/material';

import { Maybe } from '@/types';
import { Video, Asset, ImageAsset as TImageAsset, VideoAsset as TVideoAsset } from '@/types/generated';
import ImageWrapper from '@/components/ImageWrapper';
import VideoContent from '@/components/VideoContent/VideoContent';
import ImageAsset from '@/components/ImageAsset';
import VideoAsset from '@/components/VideoAsset';
import { IImage } from '@/components/ImageAsset/ImageAsset';

import {
	wrapper,
	videoStyles,
	imageStyles,
	imageAssetContainerWithSliderStyles,
	imageAssetContainerStyles,
	imageAssetContainerOneStyles,
	imageAssetContainerTwoStyles,
	beforeAfterBadgeStyles,
} from './MediaHalf.styles';
import CustomSlider from './CustomSlider/CustomSlider';

interface IMediaHalf {
	video?: Maybe<Video>;
	image?: Maybe<Asset>;
	imageSmallScreen?: Maybe<Asset>;
	isSmallScreen: boolean;
	id: string;
	imageAsset?: Maybe<TImageAsset>;
	videoAsset?: Maybe<TVideoAsset>;
	imageAssetTwo?: Maybe<TImageAsset>;
	containerSx?: SxProps;
	useStaticImageSize?: Maybe<boolean>;
}

export default function MediaHalf({
	video,
	image,
	imageSmallScreen,
	isSmallScreen,
	id,
	imageAsset,
	videoAsset,
	imageAssetTwo,
	containerSx = {},
	useStaticImageSize = false,
}: IMediaHalf) {
	const [sliderValue, setSliderValue] = useState(100);
	const containerRef = useRef<HTMLDivElement | null>(null);
	const [containerHeight, setContainerHeight] = useState<number | undefined>();
	const [heightDetermined, setHeightDetermined] = useState(false);

	useEffect(() => {
		if (!heightDetermined && imageAssetTwo) {
			const resizeObserver = new ResizeObserver((entries) => {
				for (const entry of entries) {
					if (entry.contentBoxSize[0].inlineSize > 100) {
						// Make sure the height is reasonable
						setContainerHeight(entry.contentBoxSize[0].inlineSize);
						setHeightDetermined(true); // Indicate that height has been determined
						resizeObserver.disconnect(); // Stop observing after determining height
					}
				}
			});

			if (containerRef.current) {
				resizeObserver.observe(containerRef.current);
			}

			return () => {
				resizeObserver.disconnect(); // Cleanup observer on component unmount
			};
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [imageAssetTwo]);

	useEffect(() => {
		if (heightDetermined) {
			setSliderValue(50); // Set the slider value back to 50 after height is determined
		}
	}, [heightDetermined]);

	const handleSliderChange = (_event: Event, value: number | number[], _activeThumb: number) => {
		if (typeof value === 'number') {
			setSliderValue(value);
		}
	};
	const imageData = isSmallScreen && imageSmallScreen ? imageSmallScreen : image;
	const wrapperSX: object = { ...wrapper, ...containerSx };

	if (videoAsset || imageAsset) {
		return (
			<Box data-test-id="section_media_half" id={id} sx={wrapperSX}>
				{videoAsset ? (
					<VideoAsset
						desktopVideo={videoAsset.desktopVideo as Video}
						mobileVideo={(videoAsset.mobileVideo as Video) || undefined}
						dataTestId="video_asset_half_screen_media"
					/>
				) : (
					<Box sx={imageAssetContainerWithSliderStyles(useStaticImageSize && !imageAssetTwo)}>
						<Box
							sx={imageAssetContainerStyles(
								imageAssetTwo,
								containerHeight,
								useStaticImageSize && !imageAssetTwo
							)}
							ref={containerRef}
						>
							{imageAsset && (
								<Box sx={imageAssetContainerOneStyles(imageAssetTwo, sliderValue, useStaticImageSize)}>
									{imageAssetTwo && (
										<Chip
											label={'Before'}
											variant="heavyDefault"
											sx={beforeAfterBadgeStyles(true)}
											data-test-id="media_slider_badge_before"
										/>
									)}
									<ImageAsset
										desktopImage={imageAsset?.desktopImage as IImage}
										mobileImage={imageAsset?.mobileImage as IImage}
										dataTestId="img_asset_half_screen_media"
									/>
								</Box>
							)}
							{imageAssetTwo && imageAsset && (
								<Box sx={imageAssetContainerTwoStyles(sliderValue)}>
									<ImageAsset
										desktopImage={imageAssetTwo?.desktopImage as IImage}
										mobileImage={imageAssetTwo?.mobileImage as IImage}
										dataTestId="img_asset_half_screen_media_two"
									/>
									<Chip
										label={'After'}
										variant="heavyDefault"
										sx={beforeAfterBadgeStyles(false)}
										data-test-id="media_slider_badge_after"
									/>
								</Box>
							)}
						</Box>
						{imageAssetTwo && imageAsset && (
							<CustomSlider sliderValue={sliderValue} handleSliderChange={handleSliderChange} />
						)}
					</Box>
				)}
			</Box>
		);
	}

	// TODO - Once all migrated to Image Asset and Video Asset, below code and its references can be removed.

	return (
		<Box data-test-id="section_media_half" id={id} sx={wrapperSX}>
			<>
				{video ? (
					<VideoContent
						dataTestId="video_half_screen"
						posterImage={image}
						controllableVideo={video}
						videoElSx={videoStyles}
					/>
				) : (
					<ImageWrapper
						layout="fill"
						data-test-id="half_screen_image"
						src={imageData?.url}
						alt={imageData?.description ?? ''}
						style={imageStyles}
					/>
				)}
			</>
		</Box>
	);
}
